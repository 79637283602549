<template>
  <div class="list_panel">
    <div class="list_content">
      <div :title="item.name" class="list_item" v-for="(item,idx) in listShow" :key="idx" @click="goDetailFn(item)">
        {{item.name}}
      </div>
    </div>
    <div class="pagination_content">
      <el-pagination
        background
        layout="prev, pager, next"
        :total="ktInfos.length"
        :page-size.sync="pageSize"
        :current-page.sync="currPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）

export default {
  name:"compList",
  components: {
  },
  // 定义属性
  data() {
    return {
      ktInfos:[
      ],
      currPage:1,
      pageSize:28
    }
  },
  // 计算属性，会监听依赖属性值随之变化
  computed: {
    listShow(){
      return this.ktInfos.filter((item,idx)=>{
        return idx>=(this.currPage-1)*this.pageSize&&idx<this.currPage*this.pageSize;
      })
    }
  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    goDetailFn(info){
      this.$emit("goDetail",info)
    },
    async getProductsList () {
      const { data } = await this.$http.get(`${window.SEVERURL}/safeglp/generator/products/listByType?currPage=${this.currPage}&limit=1000&category=3`)
      this.ktInfos = data.page.list
      
      this.ktInfos.forEach(item=>{
        item.content=JSON.parse(item.content)
      })
      this.totalPage = data.page.totalPage
    },
  },
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getProductsList()
  },
  beforeCreate() {}, // 生命周期 - 创建之前
  beforeMount() {}, // 生命周期 - 挂载之前
  beforeUpdate() {}, // 生命周期 - 更新之前
  updated() {}, // 生命周期 - 更新之后
  beforeDestroy() {}, // 生命周期 - 销毁之前
  destroyed() {}, // 生命周期 - 销毁完成
  activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
}
</script>

<style scoped lang="scss">
  .list_panel{
    width: 100%;
    .list_content{
      display: flex;
      flex-wrap: wrap;
      .list_item{
        width: calc(25% - 31.5px);
        height: 88px;
        background: #F6F7F9;
        border-radius: 0px 0px 0px 0px;
        border-bottom: 1px solid #0086D1;
        line-height: 87px;
        text-align: center;
        padding: 0 5px;
        box-sizing: border-box;
        margin-bottom: 42px;
        cursor: pointer;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI;
        font-weight: 400;
        font-size: 24px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        &:not(:nth-child(4n)){
          margin-right: 42px;
        }
        &:hover{
          background: #0086D1;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI;
          font-weight: bold;
          font-size: 24px;
          color: #FFFFFF;
        }
      }
    }
    .pagination_content{
      display: flex;
      justify-content: center;
    }
  }
</style>